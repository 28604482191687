import {
    mapState,
} from 'vuex'

const myPlugin = {
    install(Vue) {
        Vue.mixin({
            data: () => ({
                AccessKey: process.env.VUE_APP_ACCESS_KEY,
                SecretKey: process.env.VUE_APP_SECRET_KEY,
                api: process.env.VUE_APP_URL,
                aws:  process.env.VUE_APP_DEPLOYMENT=='TEST' ?  process.env.VUE_APP_TEST_URL_AWS +'Transaction/' :  process.env.VUE_APP_URL_AWS + 'Transaction/',
                apiKey: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key":  process.env.VUE_APP_DEPLOYMENT== 'TEST' ?  process.env.VUE_APP_TEST_API_KEY : process.env.VUE_APP_API_KEY,
                      // "Authorization":  null,
                    },
                  },
                aws2:process.env.VUE_APP_DEPLOYMENT=='TEST' ?  process.env.VUE_APP_TEST_URL_AWS +'file/' :  process.env.VUE_APP_URL_AWS + 'file/',
                apiKey2: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key":  process.env.VUE_APP_DEPLOYMENT== 'TEST' ?  process.env.VUE_APP_TEST_API_KEY : process.env.VUE_APP_API_KEY,
                    }
                  },
                  gcAWS:process.env.VUE_APP_GC_API,
                  gcAPI_Key: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_GC_API_KEY,
                    }
                  },
                  DSS_api:process.env.VUE_APP_DSS_API,
                  DSS_apiKey: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_DSS_API_KEY,
                    }
                  },
                  DSS_api2:process.env.VUE_APP_DSS_API2,
                  DSS_apiKey2: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_DSS_API_KEY2,
                    }
                  },
                windowSize: {
                    x: 0,
                    y: 0
                },
                deployment: process.env.VUE_APP_DEPLOYMENT.toLowerCase(),
            }),
            methods: {
                onResize() {
                  this.windowSize = { x: window.innerWidth, y: window.innerHeight }
                },
                parseJwt (token) {
                  var base64Url = token.split('.')[1];
                  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                  }).join(''));
                  return JSON.parse(jsonPayload);
                }
            },
            computed: {
                ...mapState([
                     'userInfo',
                ])
            }
        })
    }
}
    export default myPlugin